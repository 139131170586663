import React, { useEffect, useState, useRef } from 'react';

import './index.css';
import SelectionBar from '../../SelectionBar';
import PaginatedContent from '../../Pagination';
import ResourceItem from '../../ResourceItem';

const avalailableCategories = ['Guides', 'Reports', 'Tools', 'Webinars'];
const margin = 30;

const ResourcesSection = ({ resources }) => {
  resources.sort((a,b) => (a.node.frontmatter.date < b.node.frontmatter.date) ? 1 : -1)
  const sectionRef = useRef();
  const [selectionBarItems, setSelectionBarItems] = useState([]);
  const [updatePage, setUpdatePage] = useState(false);
  const [currentCat, setCurrentCat] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const categoriesToShow = ['All'];
    avalailableCategories.forEach((category) => {
      const showCat = resources.find(
        (resource) => resource.node.frontmatter.category === category
      );
      if (showCat) categoriesToShow.push(category);
    });
    setSelectionBarItems(categoriesToShow);
  }, []);

  useEffect(() => {
    if (!currentCat) setCurrentItems([]);
    else filterByCategory(selectionBarItems[currentCat]);
    setUpdatePage(true);
  }, [currentCat]);

  const filterByCategory = (category) => {
    setCurrentItems(
      resources.filter(
        (resource) => resource.node.frontmatter.category === category
      )
    );
  };

  const scrollToTop = () =>
    window.scrollTo(0, sectionRef.current.offsetTop - margin);

  const handlePageChange = () => {
    scrollToTop();
    setUpdatePage(false);
  };

  return (
    <section ref={sectionRef} className="resources-section">
      <div className="heading">
        <p className="title">Resources</p>
        <SelectionBar
          currentCategory={currentCat}
          setCurrentCategory={setCurrentCat}
          items={selectionBarItems}
        />
      </div>
      <div className="root-padding resources-items-container">
        <PaginatedContent
          resetPagination={updatePage}
          items={currentCat ? currentItems : resources}
          itemsPerPage={6}
          onPageChange={handlePageChange}
          RenderItem={ResourceItem}
        />
      </div>
    </section>
  );
};

export default ResourcesSection;
