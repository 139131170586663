import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/blogetrySections/Hero';
import ResourcesSection from '../../components/resourcesSections/resources';
import ImboxSection from '../../components/blogetrySections/InboxSection';

const pageTitle = 'Software & Technology Resources | Guides, Reports, Webinars | Devetry';
const pageDescription = '';

const ResourcesPageTemplate = ({ data }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('insights');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container">
        <Hero />
        <ResourcesSection resources={data} />
        <ImboxSection />
      </div>
    </>
  );
};

const ResourcesPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  return <ResourcesPageTemplate data={edges} />;
};

ResourcesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ResourcesPage;

export const pageQuery = graphql`
  query ResourcesPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "Resource/index" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            url
            date
            topic
            category
            photo {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
