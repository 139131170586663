import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';

import './index.css';
import webinar from '../../img/webinar.svg';
import tool from '../../img/tool.svg';
import guide from '../../img/guide.svg';
import report from '../../img/report.svg';

const ResourceItem = ({ item }) => {
  const [formatedDate, setFormatedDate] = useState(new Date().toDateString());

  useEffect(() => {
    const dateObj = new Date(item.date);
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    setFormatedDate(`${month}.${day}.${year}`);
  }, []);

  return (
    <div className="resource-item">
      <div className="img-container">
        <Img fluid={item.photo.childImageSharp.fluid} alt={item.name} />
      </div>
      <div className="item-data-container">
        <a href={item.url} target="_blank" rel="noreferrer">
          <div className="top-title">
            <p className="resource-topic">{item.topic}</p>
            <p className="resource-date">{formatedDate}</p>
          </div>
          <p className="resource-name">{item.title}</p>
          <p className="resource-category">
            {item.category === 'Webinars' ? (
              <img src={webinar} alt="icon" />
            ) : item.category === 'Tools' ? (
              <img src={tool} alt="icon" />
            ) : item.category === 'Guides' ? (
              <img src={guide} alt="icon" />
            ) : (
              <img src={report} alt="icon" />
            )}
            {item.category}
          </p>
        </a>
      </div>
    </div>
  );
};

export default ResourceItem;
